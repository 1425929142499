/**
*
* AdminManage
*
*/
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Grid, Stack, TextField } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { openSnackbar } from 'store/slices/snackbar';
import { gridSpacing } from 'store/constant';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';
// import { AdminValidation } from './Admin.validation'
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import Item from '../../../src/views/utilities/Grid/GridItem';
// assets
import LinkIcon from '@mui/icons-material/Link';
import { AutoCompleteField, AutoCompleteMultipleField, SwitchFieldDefault, TextFieldControlled } from 'ui-component/formsField/FormFields';
import { useEffect, useState } from 'react';
// import { createAdmin, regenerateVerificationCode, updateAdmin } from 'services/UserService';
import useAuth from 'hooks/useAuth';
import { createAdmin, regenerateVerificationCode, updateAdmin } from 'services/UserService';
import { useDispatch } from 'react-redux';
import { spinLoaderShow } from 'store/actions/spinLoader';
import { AdminValidation } from './Admin.Validation';

interface Props { apiData?: any | null }
export function AdminManage(props: Props) {
  const dispatch = useDispatch()
  const { apiData } = props  // destructuring apiData from props
  const [regenrateDisabled, setRegenerateDisabled] = useState<any>(true)
  const [type, setType] = useState<any>()
  const [disabled, setDisabled] = useState<any>(false)

  const navigate = useNavigate()


  const defautlFormValues = {
    first_name: '',
    last_name: '',
    email: '',
    status: true
  }

  const { control, formState: { errors }, setValue, getValues, handleSubmit } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defautlFormValues,
    resolver: yupResolver(AdminValidation)
  });
  useEffect(() => {
    if (apiData) {
      console.log("apiData", apiData);
      setValue("first_name", apiData?.first_name)
      setValue("last_name", apiData?.last_name)
      setValue("email", apiData?.email)
      setValue("status", apiData?.status)
      setDisabled(true)
      if (apiData?.approved == "Pending") {
        setRegenerateDisabled(false)
      }
    }
  }, [apiData])

  // create admin data
  const createonSubmit = (data: any) => {
    dispatch(spinLoaderShow(true))
    createAdmin(data).then((res: any) => {
      dispatch(spinLoaderShow(false))
      toast.success("Record inserted Successfully")
      navigate("/admin/listing", { replace: true })
    }).catch((err) => {
      dispatch(spinLoaderShow(false))
    })

  }
  // update admin data 
  const updateonSubmit = (data: any) => {
    dispatch(spinLoaderShow(true))
    updateAdmin(data).then((res: any) => {
      dispatch(spinLoaderShow(false))
      toast.success("Record updated Successfully")
      navigate("/admin/listing", { replace: true })
    }).catch((err) => {
      dispatch(spinLoaderShow(false))

    })
  }
  const onSubmit = (data: any) => {
    dispatch(spinLoaderShow(true))
    if (apiData?.uuid) {
      data.uuid = apiData?.uuid
      console.log("updateonSubmit", data)
      updateonSubmit(data)
    } else {
      // console.log(data)
      createonSubmit(data)
    }
    console.log("data", data);
  }
  console.log("verification  Email");


  const regenerateVerificationCodeAdmin = () => {
    dispatch(spinLoaderShow(true))
    regenerateVerificationCode({ uuid: apiData?.uuid }).then((res: any) => {
      dispatch(spinLoaderShow(false))
      toast.success("Code has been sent to email")
    }).catch((err: any) => {
      dispatch(spinLoaderShow(false))

    })
  }

  const theme = useTheme();
  return (
    <>
      <MainCard
        secondary={<SecondaryAction icon={<LinkIcon fontSize="small" />} link="https://formik.org/docs/examples/with-material-ui" />}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={gridSpacing}>
            <Grid item md={12} style={{ marginTop: "15px", display: "flex", justifyContent: 'right' }}>
              <SwitchFieldDefault
                errors={!!errors?.status}
                fieldName="status"
                autoComplete="off"
                label="Status"
                control={control}
                disabled={disabled}
                isLoading={true}
                setValue={setValue}
                helperText={
                  errors?.status &&
                  errors?.status?.message
                } />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControlled
                errors={!!errors?.first_name}
                fullWidth={true}
                fieldName="first_name"
                type="text"
                autoComplete='off'
                label="First Name *"
                control={control}
                valueGot={''}
                setValue={setValue}
                helperText={errors?.first_name && errors?.first_name?.message}

              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControlled
                errors={!!errors?.last_name}
                fullWidth={true}
                fieldName="last_name"
                type="text"
                autoComplete='off'
                label="Last Name *"
                control={control}
                valueGot={''}
                setValue={setValue}
                helperText={errors?.last_name && errors?.last_name?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldControlled
                errors={!!errors?.email}
                fullWidth={true}
                fieldName="email"
                type="text"
                autoComplete='off'
                label="Email *"
                disabled={disabled}
                control={control}
                valueGot={''}
                setValue={setValue}
                helperText={errors?.email && errors?.email?.message}

              />
            </Grid>

            {apiData && <Grid item xs={6} md={6} sm={6} sx={{ mt: 3 }}>
              <Button variant="contained" disabled={regenrateDisabled} onClick={() => regenerateVerificationCodeAdmin()}>
                Regenerate Code
              </Button>
            </Grid>}
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="flex-end">
                <AnimateButton>
                  <Button variant="contained" type="submit" disabled={apiData  ? true : false}>
                    {apiData ? "Update" : "Create"}
                  </Button>
                </AnimateButton>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </MainCard>


    </>
  )
}