/**
 *
 * AdminView
 *
 */
import {
    Box,
    Button,
    CardMedia,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React from 'react';
import { toast } from 'react-toastify';
import { IconCopy } from '@tabler/icons';

interface Props {}

export default function QuestionnearDetail(props: any) {
    const { apiData } = props;

    const [questions, setQuestions] = useState([
        { question_1: 'Why do you want to be a member of the #SephoraSquad' },
        { question_2: 'Tell us about your journey. How did you get started as a Beauty Content Creator' },
        { question_3: 'Name a beauty influencer who inspires you. Why?' },
        { question_4: 'Tell us about your passion for beauty. How has it shaped your life?' },
        { question_5: 'What dose Sephora mean to you? How has it impacted your relationship with beauty' },
        {
            question_6: 'What Sephora products or brands are your current favorites and why? How do you use them? please name at least five'
        },
        { question_7: 'What dose sephora means to you? How has it impacted your relationship with beauty ?' },
        { question_8: 'Are you licensed to perfome any professional beauty services?' },
        { question_9: 'What beauty tip do you love4 sharing with clients or friends' },
        { question_10: 'test' }
    ]);
    const [copyValue, setCopyValue] = React.useState('');
    const [copied, setCopied] = React.useState(false);
    const getAdminData = () => {
        // dispatchLoader(true)
        // getAdmin({ uuid: uuid }).then((res: any) => {
        //   res = res?.map((item: any) => {
        //     item.user_type = item?.user_type == "SERVICEPROVIDER" ? "SERVICE PROVIDER" : item?.user_type
        //     return item
        //   })
        //   setApiData(res[0])
        //   dispatchLoader(false)
        // }).catch((err:any) => {
        //   dispatchLoader(false)
        // })
    };

    useEffect(() => {
        getAdminData();
    }, []);

    const notify = () =>
        toast.success('Url copied to clipboard successfully !', {
            position: 'bottom-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
        });

    const onCopy = React.useCallback((e: any) => {
        setCopied(true);
    }, []);

    const onClick = React.useCallback(({}) => {
        notify();
    }, []);
    const openInNewTab = (url: any) => {
        window.open(url, '_blank', 'noreferrer');
    };

    console.log('apiData', apiData);

    useEffect(() => {
        if (apiData?.questionnaire[0]?.additional_video) {
            setCopyValue(`${apiData?.questionnaire[0]?.additional_video}`);
        }
    }, [apiData]);

    return (
        <>
            {/* <CustomizedBreadCrumb title="Questionnear Details" /> */}
            <Grid container direction="column" spacing={2} style={{ marginTop: '30px' }}>
                <Grid item xs={12}>
                    <SubCard>
                        {/* {questions.map((item: any, key) => {
              return (
                <Accordion key={key}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography variant="h3">{item}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="h3">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                      malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              )
            })
            } */}

                        <div>
                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="subtitle1">
                                            Upload a short video explaining why you would like to be part of the Sephora Squad ?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={3}>
                                            <Grid item md={12} xs={12} sm={12}>
                                                <CardMedia
                                                    component="video"
                                                    style={{ width: '200px', height: '160px' }}
                                                    image={
                                                        apiData?.questionnaire[0]?.additional_video &&
                                                        apiData?.questionnaire[0]?.additional_video
                                                    }
                                                    controls={true}
                                                />
                                                <div style={{ marginTop: '5px' }}>
                                                    <CopyToClipboard text={copyValue} onCopy={onCopy}>
                                                        <IconCopy onClick={onClick} />
                                                    </CopyToClipboard>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>
                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                        <Typography variant="subtitle1">Why do you want to be a member of the #SephoraSquad</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_1}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">
                                            Tell us about your journey. How did you get started as a Beauty Content Creator
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_2}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">Name a beauty influencer who inspires you. Why?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_3}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">
                                            Tell us about your passion for beauty. How has it shaped your life?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_4}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">
                                            What dose Sephora mean to you? How has it impacted your relationship with beauty
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_5}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">
                                            What Sephora products or brands are your current favorites and why? How do you use them? please
                                            name at least five
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_6}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">
                                            What dose sephora means to you? How has it impacted your relationship with beauty ?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_7}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">
                                            Are you licensed to perfome any professional beauty services?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_8}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">
                                            What beauty tip do you love4 sharing with clients or friends
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_9}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">
                                            Where did you learn about the Sephora Squad program from?
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.question_11}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>

                            <SubCard sx={{ mb: 2 }}>
                                <Accordion>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                        <Typography variant="subtitle1">Are you part of the Sephora Loyalty program?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography fontSize={'18px'} variant="subtitle2">
                                            {apiData?.questionnaire[0]?.is_loyalty ? 'Yes' : 'No'}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </SubCard>
                        </div>
                    </SubCard>
                </Grid>
            </Grid>
        </>
    );
}
