/**
*
* CustomerDetails
*
*/
import * as React from 'react';
// import CustomerManage from 'components/CustomerManage/index'
import { useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router-dom';
// import { getCustomer } from 'services/customerServices'
import CustomizedBreadCrumb from 'ui-component/CustomBreadCrumbs';
import { CustomerManage } from 'components/CustomerManage';

interface Props { }

export default function CustomerDetails(props: Props) {
  const [apiData, setApiData] = useState<any>(null)
//   const { dispatchLoader } = useAuth();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const getApiData = () => {
    return
    // dispatchLoader(true)
    // getCustomer({ uuid: uuid }).then((res: any) => {
    //   setApiData(res[0])
    // //   dispatchLoader(false)

    // }).catch((err: any) => {
    // //   dispatchLoader(false)

    // })
  }

  useEffect(() => {
    getApiData()
  }, [uuid])

  return (
    <>
      <CustomizedBreadCrumb title="Customer Details" firstPage="Customers Listing" firstRoute="/dashboard/customer/listing" secondPage="Customer Details" secondRoute="/dashboard/customer/details" />
      <CustomerManage apiData={apiData} />
    </>
  );

};

