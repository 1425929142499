import httpservice from "./httpservice";

export function getCustomer(body: any = null) {
    return httpservice.get("/customer", { params: body });
}

export function createCustomer(body: any) {
    return httpservice.post("/customer", body);
}
export function updateCustomer(body: any) {
    return httpservice.put("/customer", body);
} 
export function updateCustomerStatus(body: any) {
    return httpservice.put("/customer/update/bulk/applicant/status", body);
} 

export function customerActivation(body: any) {
    return httpservice.put("/customer/activation", body);
} 

export function updateApplicantStatus(body: any) {
    return httpservice.put("/customer/update/applicant/status", body);
} 


