import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'axios';

// types
import { KeyedObject } from 'types';
import { InitialLoginContextProps, JWTContextType } from 'types/auth';
import { login as LoginApi } from 'services/fusionauthservices';
import { toast } from 'react-toastify';
import { FusionAuthClientInstance } from 'services/FusionAuthClient';
import { application_id } from 'utils/constant/index';
const chance = new Chance();

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    userType: null,
    loader: false
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    return true;
};

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken: any = window.localStorage.getItem('serviceToken');
                let getUser = await FusionAuthClientInstance.retrieveUserUsingJWT(serviceToken);
                if (serviceToken && verifyToken(serviceToken) && getUser) {
                    setSession(serviceToken);
                    // const response = await axios.get('/api/account/me');
                    // const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {},
                            userType: getUser?.response?.user?.data?.type
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);

    useEffect(() => {});

    const login = async (email: string, password: string) => {
        // const response = await axios.post('https://shafidevauth.demoz.agency/api/login', { email, password , Authorization:});
        // const response = await LoginApi({email,password})
        // console.log("Response",response)
        try {
            const applicationId = process.env.REACT_APP_APPLICATION_ID;

            let response: any = await FusionAuthClientInstance.login({
                loginId: email,
                password: password,
                applicationId: process.env.REACT_APP_APPLICATION_ID
            });

            const { token, user } = response.response;
            setSession(token);

            // localStorage.setItem("id",response.response)
            if (response.wasSuccessful()) {
                toast.success('Successfully Logged In');
            } else {
                toast.error('invalid email or password');
            }

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user,
                    userType: user?.data?.type
                }
            });
        } catch (error) {
            toast.error('invalid email or password');
        }
    };

    const dispatchLoader = (payload: any) => {
        // dispatch({
        //     type: LOADER,
        //     payload: { loader: payload }
        // });
    };

    const register = async (email: string, password: string) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers!),
                {
                    id,
                    email,
                    password
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = (email: string) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile, dispatchLoader }}>
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
