import * as yup from "yup";


const taskValidations = yup.object().shape({
    questions: yup.array().of(
        yup.object().shape({
            topic: yup.string().required("Please enter question").nullable(),
            topic_ar: yup.string().required("Please enter question in arabic").nullable(),
        }),
    ),
    
})

export {
    taskValidations,

}