/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/*eslint-Disable */
/**
*
* ProjectManage
*
*/
import React, { useEffect, useState } from 'react';

// material-ui
import { Box, Card, Chip, Grid, Tab, Tabs, Typography } from '@mui/material';

// assets
import SubCard from 'ui-component/cards/SubCard';
import { spinLoaderShow } from 'store/actions/spinLoader';
import { useDispatch } from 'react-redux';
import QuestionnearDetail from 'pages/TabViewComponent/questionnearDetail';
import TaskDetail from './TaskDetail';
import UserProfile from './UserDetail';
import { useLocation } from 'react-router-dom';
import { getCustomer } from 'services/CustomerService';
import CustomerSettings from './Settings';


interface Props { }

// ================================|| UI TABS  ||================================ //

export default function TabViewComponent(props: any) {
  const [apiData, setApiData] = useState<any>(null)
  const [disabled, setDisabled] = useState<any>(true)
  const [value, setValue] = React.useState("profile"); 
  const errorColor :any = {
    "rejected_round_1" :"error",
    "rejected_round_2" :"error", 
     "finalist": "success",
     "winner": "success",
     "semifinalist": "success", 
     "pending":"warning"

  } 
  const applicantStatus:any ={ 
    "rejected_round_1" :"Rejected Round 1",
    "rejected_round_2" :"Rejected Round 2", 
     "finalist": "Finalist",
     "winner": "Winner",
     "semifinalist": "Semi Finalist", 
     "pending":"Pending"

  }
  const dispatch = useDispatch()
  const { search } = useLocation()
  let customer_id = new URLSearchParams(search).get("customer_id");

  const getApiData = () => {
    dispatch(spinLoaderShow(true))
    getCustomer({ uuid: customer_id }).then((res: any) => {
      setApiData(res[0])
      dispatch(spinLoaderShow(false))
    }).catch((err: any) => {
      dispatch(spinLoaderShow(false))
    })
  }

  useEffect(() => {
    getApiData()
  }, [customer_id])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(spinLoaderShow(true))
    if (apiData?.uuid) {
      dispatch(spinLoaderShow(false))
      setDisabled(false)
    }
  }, [apiData?.uuid])

  return (
    <>
      <SubCard >
        <Card>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box sx={{ width: '100%' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab value="profile" label="User Profile" />
                  <Tab value="questionnaire" label="Questionnaire" />
                  <Tab value="task" label="Task Detail" />
                  <Tab value="settings" label="Settings" /> 
                  <Tab sx={{ml:116}} icon={ <Chip size="medium" label={ applicantStatus?.[apiData?.application_status]} color={errorColor?.[apiData?.application_status]}/>} disabled={true}/> 
                </Tabs>
              </Box>

              {value == "profile" && (
                <UserProfile apiData={apiData} />
              )}
              {value == "questionnaire" && (
                <QuestionnearDetail apiData={apiData} />
              )}
              {value == "task" && (
                <TaskDetail apiData={apiData} />
              )}
              {value == "settings" && (
                <CustomerSettings apiData={apiData} refreshData={getApiData} />
              )}
            </Grid>
          </Grid>


        </Card>
      </SubCard>
    </>
  )
}