// material-ui
import { Button, Grid, Typography } from '@mui/material';
// project imports
import useAuth from 'hooks/useAuth';
import SubCard from 'ui-component/cards/SubCard';

import { useForm } from "react-hook-form";
import { SwitchFieldDefault } from 'ui-component/formsField/FormFields';
import {customerActivation, updateApplicantStatus} from 'services/CustomerService'
import { useEffect } from 'react';
import { spinLoaderShow } from 'store/actions/spinLoader';
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
// personal details table
/** names Don&apos;t look right */
function createData(name: string, calories?: string, fat?: string, carbs?: string, protein?: string) {
  return { name, calories, fat, carbs, protein };
}

// ==============================|| PROFILE 1 - PROFILE ||============================== //

const CustomerSettings = (props: any) => {
  const { user } = useAuth();
  const { apiData } = props
  const dispatch = useDispatch()
  const defautlFormValues = {
    first_name: '',
    last_name: '',
    email: '',
    status: true
  }

  const { control, formState: { errors }, setValue, getValues, handleSubmit } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defautlFormValues,
    // resolver: yupResolver(AdminValidation)
  });
 
  useEffect(()=>{ 
    if(props?.apiData) {
      setValue("status",props?.apiData?.status)
    }

  },[props]) 

  const onStatusChange =(data:any) =>{
    let payload:any = { 
      uuid: props?.apiData?.uuid,
      status: data

    } 
    dispatch(spinLoaderShow(true))
    customerActivation(payload).then((res: any) => { 
      toast.success("Record updated Successfully")
    props?.refreshData()
      dispatch(spinLoaderShow(false))
    }).catch((err) => {
      console.log("err", err);
      dispatch(spinLoaderShow(false))
    })

  }
   
  const onApplicantStatusChange = (status:any,message:any) => { 
    let payLoad:any = {
      uuid: props?.apiData?.uuid,
      application_status: status
    }
    dispatch(spinLoaderShow(true))
    updateApplicantStatus(payLoad).then((res:any)=>{
      toast.success(message) 
    dispatch(spinLoaderShow(false))

    }).catch((err)=>{
    dispatch(spinLoaderShow(false))

    })
  }

  return (
    <Grid container spacing={2} style={{ marginTop: "10px" }}>
      <Grid item lg={12} xs={12} >
        <SubCard >
          <Grid item xs={12} sm={12} md={12} >

            <Grid container spacing={2}> 
            <Grid item md={12} style={{ marginTop: "15px", display: "flex", justifyContent: 'right' }}>
                <SwitchFieldDefault
                  errors={!!errors?.status}
                  fieldName="status"
                  autoComplete="off"
                  label="Enable/Disable Applicant"
                  control={control}
                  // disabled={disabled}
                  isLoading={true} 
                  iProps={{
                    onChange:onStatusChange
                  }}
                  setValue={setValue}
                  helperText={
                    errors?.status &&
                    errors?.status?.message
                  } />
              </Grid>
                  
               <Grid item md={3}  style={{ marginTop: "25px", display: "flex", justifyContent: 'center' }}>
                <Typography variant="h3">Applicant Status : </Typography>
               </Grid>
              <Grid item md={3} style={{ display: "flex", justifyContent: 'center' }}>
              <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      color="primary"  
                      disabled={apiData?.application_status == "finalist" ||apiData?.application_status == "semifinalist"  || apiData?.application_status == "winner"  || apiData?.application_status == "rejected_round_1" 
                      || apiData?.application_status == "rejected_round_2" }
                      onClick={()=> onApplicantStatusChange("semifinalist", "status changed to semi finalist successfully")}
                    >
                      Semi Finalist
                    </Button>
              </Grid> 
              <Grid item md={3} style={{ display: "flex", justifyContent: 'center' }}>
              <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      color="primary" 
                      disabled={ apiData?.application_status == "finalist" || apiData?.application_status == "winner"|| apiData?.application_status == "rejected_round_1" || apiData?.application_status == "rejected_round_2"  }
                      onClick={()=> onApplicantStatusChange("finalist", "status changed to finalist successfully")}
                     
                    >
                       Finalist
                    </Button>
              </Grid>
              <Grid item md={3} style={{ display: "flex", justifyContent: 'center' }}>
              <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      color="primary"
                      disabled={apiData?.application_status == "semifinalist"  || apiData?.application_status == "winner" || apiData?.application_status == "rejected_round_1"  || apiData?.application_status == "rejected_round_2" } 
                      onClick={()=> onApplicantStatusChange("winner", "status changed to winner successfully")}

                    >
                       Winner
                    </Button>
              </Grid>
              
            </Grid>

          </Grid>
        </SubCard>
      </Grid>
    </Grid>
  );
};

export default CustomerSettings;
