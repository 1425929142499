import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import { Card, Grid } from '@mui/material';
import { Link as RouterLink, } from 'react-router-dom';
function handleClick(event: any) {
    event.preventDefault();
    console.info('You clicked a breadcrumb.');
}

export default function CustomizedBreadCrumb(props: any) {
    return (
        <Card sx={{ height: 60, mb: 3, }}>
            <Grid container >
                <Grid item md={6} sm={6} xs={6} sx={{ pt: 2, pl: 2 }} >
                    <Typography variant="h3">{props?.title}</Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6} sx={{ display: 'flex', justifyContent: 'right', pt: 2.3, pr: 2 }}>
                    <Breadcrumbs aria-label="breadcrumb">



                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href="/dashboard/default"

                        >
                            <RouterLink to="/dashboard/default" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                Dashboard

                            </RouterLink>
                        </Link>
                        {props?.firstPage && <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                            href={props?.firstRoute}       >
                            <RouterLink to={props?.firstRoute} style={{ color: 'inherit', textDecoration: 'inherit' }}>

                                {props?.firstPage}
                            </RouterLink>
                        </Link>}
                        {props?.secondPage && <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary"
                        >

                            {props?.secondPage}
                        </Typography>}
                    </Breadcrumbs>
                </Grid>


            </Grid>

        </Card>
    );
}
