import { useEffect, useState } from 'react';

// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import Loader from 'ui-component/Loader';
import ThemeCustomization from 'themes';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { dispatch } from 'store';
import { getDashboard } from 'store/slices/menu';

// import { ToastContainer } from "react-toastify";

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import BackDrop from 'ui-component/backdrop';
// import { ToastContainer } from "react-toastify";

// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {

  return (
    <>
      <ToastContainer  />
      <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
          <Locales>
            <NavigationScroll>
              <AuthProvider>
                <>
                  <Routes />
                  <Snackbar />
                  <BackDrop />
                </>
              </AuthProvider>
            </NavigationScroll>
          </Locales>
        </RTLLayout>
      </ThemeCustomization>
    </>
  )

};

export default App;
