// material-ui
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  LinearProgressProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import Avatar from 'ui-component/extended/Avatar';
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';

// assets
import { IconEdit } from '@tabler/icons';
import PhonelinkRingTwoToneIcon from '@mui/icons-material/PhonelinkRingTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';

import Avatar3 from 'assets/images/users/user-3.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from "swiper";
import 'swiper/css';
import MainCard from 'ui-component/cards/MainCard';
import { useEffect } from 'react';
import { CardMedia } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import React from 'react';
// personal details table
/** names Don&apos;t look right */
function createData(name: string, calories?: string, fat?: string, carbs?: string, protein?: string) {
  return { name, calories, fat, carbs, protein };
}

// ==============================|| PROFILE 1 - PROFILE ||============================== //

const TaskDetail = (props: any) => {
  const { user } = useAuth();
  const [copyValue, setCopyValue] = React.useState('');
  const [copied, setCopied] = React.useState(false);
  const { apiData } = props


  useEffect(() => {

    console.log("rrrr", props?.apiData)

  }, [props])




  const onButtonClick = (type: any,item:any) => {
    if (type == "video") {
      setCopyValue(apiData?.task[0]?.video) 
      notify()
    } else if(type == "multi" && item) {
      setCopyValue(item)
      notify()
     

    }


  }

  const onCopy = React.useCallback((e: any) => {
    setCopied(true);
  }, [])

  const notify = () => toast.success("Url copied to clipboard successfully !", {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  })
  const onClick = React.useCallback(({ }) => {
    notify()
  }, [])
  const openInNewTab = (url: any) => {
    window.open(url, '_blank', 'noreferrer');
  };
  useEffect(() => {
    if (apiData?.task[0]?.video) {
      setCopyValue(`${apiData?.task[0]?.video}`)
    }
  }, [apiData])

  useEffect(() => {
    console.log("copyValue", copyValue)
  }, [copyValue])

  return (
    <Grid container spacing={3} style={{ marginTop: "10px" }}>
      {/* <Grid item md={12} xs={12} sm={12}>
        <Grid container spacing={3}>
          <Grid item md={8} sm={8} xs={8}></Grid>
          <Grid item md={4} xs={4} sm={4} display={"flex"} justifyContent={"right"}>
            <Box>
          
              <Button sx={{ m: 2, minWidth: "100px" }}
                variant="contained"
                color="primary"
                onClick={() => { onButtonClick("video") }}
              >
                Video
              </Button>

            </Box>


          </Grid>

        </Grid>
      </Grid> */}
      <Grid item lg={12} xs={12} >
        <SubCard title="Question" >
          <Grid item xs={12} sm={12} md={12} >

            <Grid container spacing={3}>
              <Grid item md={12} xs={12} sm={12} sx={{ display: "flex", justifyContent: "left" }}>
                <Typography variant='h3'>{props?.apiData?.task[0]?.topic && props?.apiData?.task[0]?.topic?.topic}</Typography>
              </Grid>
              <Grid item md={12} xs={12} sm={12} sx={{ display: "flex", justifyContent: "right" }}>
                <Typography variant='h3'>{props?.apiData?.task[0]?.topic && props?.apiData?.task[0]?.topic?.topic_ar}</Typography>
              </Grid>
            </Grid>

          </Grid>
        </SubCard>
      </Grid>
      <Grid item md={12} xs={12} sm={12}>
        <SubCard title="Video">
          <Grid item md={12} xs={12} sm={12}>

            <Swiper
              style={{ marginTop: "15px" }}
              modules={[Navigation, A11y]}
              spaceBetween={0}
              slidesPerView={8}
              onSlideChange={() => console.log('slide change')}
              navigation
            >  <Grid container spacing={3}>
                {(Array.isArray(apiData?.task[0]?.video) == true) ? apiData?.task[0]?.video?.map((item: any) => {
                  return <SwiperSlide>

                    <Grid item xs={3} md={3} sm={3}>
                      <CopyToClipboard text={copyValue}
                        onCopy={onCopy}>



                        <Button
                          onClick={() => { onButtonClick("multi",item) }}
                          variant="contained"
                          color="primary"
                        >Copy video URL</Button>
                      </CopyToClipboard>
                    </Grid>

                  </SwiperSlide>
                }) : (apiData?.task[0]?.video && <Grid item xs={3} md={3} sm={3}>
                  <CopyToClipboard text={copyValue}
                    onCopy={onCopy}>
                    <Button
                      onClick={() => { onButtonClick("video",null) }}
                      variant="contained"
                      color="primary"
                    >Copy video URL</Button>
                  </CopyToClipboard>
                </Grid>)}
              </Grid>

            </Swiper>

          </Grid>

        </SubCard>

      </Grid>
    </Grid>
  );
};

export default TaskDetail;
