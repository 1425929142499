// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconChartArcs, IconClipboardList, IconChartInfographic, IconApps, IconUserCheck } from '@tabler/icons';
import { NavItemType } from 'types';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CategoryIcon from '@mui/icons-material/Category';
import GroupIcon from '@mui/icons-material/Group';
import TaskIcon from '@mui/icons-material/Task';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
// constant
const icons = {
    IconChartArcs,
    IconClipboardList,
    IconChartInfographic,
    IconApps,
    IconUserCheck
};
const muiIcons = {
    AdminPanelSettingsIcon,
    CategoryIcon,
    GroupIcon,
    TaskIcon,
    SettingsApplicationsIcon
};
// ==============================|| WIDGET MENU ITEMS ||============================== //

const Admin: NavItemType = {
    id: 'admin',
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'admin',
            title: <FormattedMessage id="Admin" />,
            type: 'collapse',
            icon: muiIcons.AdminPanelSettingsIcon,
            children: [
                {
                    id: 'adminListing',
                    title: (
                        <>
                            <FormattedMessage id="Listing" />
                        </>
                    ),
                    type: 'item',
                    url: '/admin/listing'
                },
                {
                    id: 'adminCreate',
                    title: (
                        <>
                            <FormattedMessage id="Create" />
                        </>
                    ),
                    type: 'item',
                    url: '/admin/add'
                },

            ]
        },
        {
            id: 'customer',
            title: <FormattedMessage id="Applicants" />,
            type: 'collapse',
            icon: muiIcons.GroupIcon,
            children: [
                {
                    id: 'Customerlisting',
                    title: (
                        <>
                             <FormattedMessage id="Listing" />
                        </>
                    ),
                    type: 'item',
                    url: '/customer/listing'
                },
                // {
                //     id: 'CustomerCreate',
                //     title: (
                //         <>
                //              <FormattedMessage id="Create" />
                //         </>
                //     ),
                //     type: 'item',
                //     url: '/customer/add'
                // },

            ]
        },
        // {
        //     id: 'Customerlisting',
        //     title: <FormattedMessage id="customer" />,
        //     type: 'collapse',
        //     icon: muiIcons.AdminPanelSettingsIcon,
        //     children: [
        //         {
        //             id: 'CustomerListing',
        //             title: (
        //                 <>
        //                     <FormattedMessage id="Listing" />
        //                 </>
        //             ),
        //             type: 'item',
        //             url: 'customer/listing'
        //         },
        //         {
        //             id: 'CustomerCreate',
        //             title: (
        //                 <>
        //                     <FormattedMessage id="Create" />
        //                 </>
        //             ),
        //             type: 'item',
        //             url: 'customer/add'
        //         },

        //     ]
        // },
        {
            id: 'task',
            title: <FormattedMessage id="Task" />,
            type: 'collapse',
            icon: muiIcons.TaskIcon,
            children: [
                // {
                //     id: 'taskListing',
                //     title: (
                //         <>
                //             <FormattedMessage id="Submitted Listing" />
                //         </>
                //     ),
                //     type: 'item',
                //     url: '/task/listing'
                // },
                {
                    id: 'taskCreate',
                    title: (
                        <>
                            <FormattedMessage id="Manage" />
                        </>
                    ),
                    type: 'item',
                    url: '/task/add'
                },

            ]
        },
          {
            id: 'Setting-setting',
            title: <FormattedMessage id="Application Setting" />,
            type: 'collapse',
            icon: muiIcons.SettingsApplicationsIcon,
            children: [
                {
                    id: 'application-setting',
                    title: (
                        <>
                            <FormattedMessage id="Settings" />
                        </>
                    ),
                    type: 'item',
                    url: '/application-settings'
                },
               

            ]
        },

    ]
}

export default Admin;
