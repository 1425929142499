import * as yup from 'yup';

const AdminValidation: any = yup.object().shape({
    first_name: yup
        .string()
        .matches(/^[A-Za-z]+$/, 'Name Only Without Space')
        .max(15, 'Max character limit is 15')
        .required('Please enter first name')
        .nullable(),
    last_name: yup
        .string()
        .matches(/^[A-Za-z]+$/, 'Name Only Without Space')
        .max(15, 'Max character limit is 15')
        .required('Please enter last name')
        .nullable(),
    email: yup.string().required('Please enter email').email().nullable(),

});
export { AdminValidation };
