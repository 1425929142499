// material-ui
import {
  Box,
  Button,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  LinearProgressProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import Avatar from 'ui-component/extended/Avatar';
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';

// assets
import { IconEdit } from '@tabler/icons';
import PhonelinkRingTwoToneIcon from '@mui/icons-material/PhonelinkRingTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PublicIcon from '@mui/icons-material/Public';
import WcIcon from '@mui/icons-material/Wc';
import LanguageIcon from '@mui/icons-material/Language';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { useUnmountEffect } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, A11y } from "swiper";
import { IconBrandSnapchat, IconBrandTiktok, IconBrandTwitch } from '@tabler/icons'
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FlagIcon from '@mui/icons-material/Flag';
import 'swiper/css';
import Viewer from 'react-viewer';
import React from 'react';

// progress
function LinearProgressWithLabel({ value, ...others }: LinearProgressProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Box
        sx={{
          width: '100%',
          mr: 1
        }}
      >
        <LinearProgress value={value} {...others} />
      </Box>
      <Box
        sx={{
          minWidth: 35
        }}
      >
        <Typography variant="body2" color="textSecondary">{`${Math.round(value!)}%`}</Typography>
      </Box>
    </Box>
  );
}

// personal details table
/** names Don&apos;t look right */
function createData(name: string, calories?: string, fat?: string, carbs?: string, protein?: string) {
  return { name, calories, fat, carbs, protein };
}

// ==============================|| PROFILE 1 - PROFILE ||============================== //

const UserProfile = (props: any) => {
  const { user } = useAuth();
  const { apiData } = props
  const [visible, setVisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [CardImage, setCardImage] = useState<any>(["http://sephoradevassets.demoz.agency/assets/1684056174875-unnamed - Copy (5).jpg", "http://sephoradevassets.demoz.agency/assets/1684056174875-unnamed - Copy (5).jpg", "http://sephoradevassets.demoz.agency/assets/1684056174875-unnamed - Copy (5).jpg", "http://sephoradevassets.demoz.agency/assets/1684056174875-unnamed - Copy (5).jpg"])
  const [CardImageVisible, setCardImageVisible] = useState<any>(false)

  const showViewer = (index: any) => {
    console.log("dddd", index)
    setActiveIndex(index);
    setVisible(true);
  };

  const hideViewer = () => {
    setVisible(false);
  };


  return (
    <Grid container spacing={2} style={{ marginTop: "30px" }}>
      <Grid item lg={12} xs={12}>
        <SubCard
          title={
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar size="lg" alt="User 1" src={apiData?.profileImage} />
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography align="left" variant="subtitle1">
                  {user?.name}
                </Typography>
                <Typography align="left" variant="h4">
                  {apiData?.last_name ? apiData?.first_name + " " + apiData?.last_name : apiData?.first_name}
                </Typography>
              </Grid>
            </Grid>
          }
        >
          <List component="nav" aria-label="main mailbox folders">
            <ListItem>
              <ListItemIcon>
                <LanguageIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Language</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.secondary_language}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Email</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.email}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Sephora Pass Email</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.sephora_pass_email}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />
            <ListItem>
              <ListItemIcon>
                <PhonelinkRingTwoToneIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Phone</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.phone_number}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />
            <ListItem>
              <ListItemIcon>
                <LocationCityIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">City</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.location_city}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <FlagIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Country</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.location_country}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <WcIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Gender</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.gender}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />
            <ListItem>
              <ListItemIcon>
                <FacebookIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Facebook</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.facebook_link}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />
            <ListItem>
              <ListItemIcon>
                <InstagramIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Instagram</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.instagram_link}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <YouTubeIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Youtube</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.youtube_link}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <IconBrandSnapchat />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Snapchat</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.snapchat_link}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <IconBrandTiktok />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Tiktok</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.tiktok_link}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <IconBrandTwitch />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Twitch</Typography>} />
              <ListItemSecondaryAction>
                <Typography variant="subtitle2" align="right">
                  {apiData?.twitch_link}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem>
              <ListItemIcon>
                <TipsAndUpdatesIcon sx={{ fontSize: '1.3rem' }} />
              </ListItemIcon>
              <ListItemText primary={<Typography variant="subtitle1">Social Insights</Typography>} />
            </ListItem>
            <ListItem>
              <ListItemText>

                {/* <img onClick={() => setCardImageVisible(true)} src={CardImage ? CardImage : "https://cdn-icons-png.flaticon.com/512/179/179378.png"} />
              {CardImage && <Viewer
                  visible={CardImageVisible}
                  onClose={() => { setCardImageVisible(false); }}
                  images={[{ src: CardImage, alt: '' }]}
                  noNavbar={true}
                  noToolbar={true}
                  onMaskClick={() => setCardImageVisible(false)}

                />} */}
                <Swiper

                  modules={[Navigation, A11y]}
                  spaceBetween={0}
                  slidesPerView={8}
                  onSlideChange={() => console.log('slide change')}
                  navigation
                >  <Grid container spacing={3}>
                    {props?.apiData?.social_insights && JSON.parse(props?.apiData?.social_insights)?.map((image: any, index: any) => (
                      <SwiperSlide key={index}>
                        {/* <img
                          src={image}
                          alt={`Image ${index + 1}`}
                          onClick={() => showViewer(index)}
                        /> */}
                        <Grid item xs={3} md={3} sm={3}>
                          <CardMedia
                            component="img"

                            style={{ width: "150px", height: "120px" }}
                            src={image}
                            alt={`Image ${index + 1}`}
                            onClick={() => showViewer(index)}

                          />
                        </Grid>
                      </SwiperSlide>
                    ))}


                  </Grid>

                </Swiper>
              </ListItemText>
            </ListItem>
          </List>
          <Viewer
            visible={visible}
            onClose={hideViewer}
            images={props?.apiData?.social_insights && JSON.parse(props?.apiData?.social_insights)?.map((url: any) => ({ src: url }))}
            onMaskClick={()=>{ setVisible(false)}}
            activeIndex={activeIndex}
          />
          <CardContent>
          </CardContent>


        </SubCard>
      </Grid>
    </Grid>
  );
};

export default UserProfile;
