/**
*
* TaskUpdate
*
*/
import * as React from 'react';
import { TaskManage } from 'components/TaskManage/index';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import { getAdmin } from 'services/UserService';
import useAuth from 'hooks/useAuth';
import CustomizedBreadCrumb from 'ui-component/CustomBreadCrumbs';

interface Props { }

export default function TaskUpdate(props: Props) {
  const [apiData, setApiData] = useState<any>(null)
//   const { dispatchLoader } = useAuth();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const getTaskData = () => {
    // dispatchLoader(true)
    // getAdmin({ uuid: uuid }).then((res: any) => {
    //   console.log("ressss", res)
    //   setApiData(res[0])
    //   dispatchLoader(false)
    // }).catch((err) => {
    // //   dispatchLoader(false)
    // })
  }
  useEffect(() => {
    getTaskData()
  }, [])

  return (<>
    <CustomizedBreadCrumb title="Task Manage" firstPage="Task Manage" firstRoute="/dashboard/task/listing"  secondRoute="/dashboard/task/edit" />

    <TaskManage apiData={apiData} />
  </>
  );

};