/**
 *
 * SocialManage
 *
 */
import { Button, CardMedia, Grid, Stack } from '@mui/material';
import * as React from 'react';
import { Fragment, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { gridSpacing } from 'store/constant';
import { DropzoneComponent } from 'ui-component/Dropzone';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { SwitchFieldDefault, TextEditorField, TextFieldControlled } from 'ui-component/formsField/FormFields';
import DeleteIcon from '@mui/icons-material/Delete';
import SubCard from 'ui-component/cards/SubCard';
import useAuth from 'hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import { spinLoaderShow } from 'store/actions/spinLoader';
import { useDispatch } from 'react-redux';
import { createTask, createTopicByAdmin, updateTask } from 'services/TaskService';
import { taskValidations } from './validations/task.validation';
import { toast } from 'react-toastify';




interface Props { }

export function TaskManage(props: any) {
  const dispatch = useDispatch()
  const { apiData, updateData, disabled } = props
  const { dispatchLoader } = useAuth();
  const [errorMessage, setErrorMessage] = useState<any>("")
  const {
    control,
    formState: { errors },
    setValue,
    getValues,
    handleSubmit
  } = useForm<any>({
    mode: 'onChange',
    reValidateMode: 'onChange',

    resolver: yupResolver(taskValidations)
  });
  const {
    fields: task,
    append: append,
    remove: remove
  } = useFieldArray({
    //field array defined here
    control,
    name: 'questions'
  });

  useEffect(() => {
    if (apiData) {
      setValue("questions", apiData)
    }

  }, [apiData])

  useEffect(() => {
    if (errors) {
      setErrorMessage(errors)
    }
  }, [errors])


  //function for submited form 
  const onSubmit = (data: any) => {
    
    dispatch(spinLoaderShow(true))
   createOnSubmit(data)


  }

  const createOnSubmit = (data: any) => {
    createTopicByAdmin(data).then(res => {
      toast.success("Record inserted Successfully")
      dispatch(spinLoaderShow(false))
     props?.refreshData()
    }).catch((err) => {
      console.log("err", err);
      dispatch(spinLoaderShow(false))

    })

  }
  const updateOnSubmit = (data: any) => {
    updateTask(data).then((res: any) => {
      toast.success("Record updated Successfully")
      // navigate("/dashboard/customer/listing")
      dispatch(spinLoaderShow(false))
    }).catch((err) => {
      console.log("err", err);
      dispatch(spinLoaderShow(false))
    })

  }


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SubCard >
          <Grid item xs={6} md={6} sm={6} >
            <SubCard >
              <Grid container spacing={2}>
                {task &&
                  task?.map((item: any, index: any) => {
                    return (
                      <>
                        <Grid item xs={12} md={12} sm={12} sx={{ ml: 1, mt: 2 }}>
                          <Grid container spacing={1}>  
                           {item?.uuid && <TextFieldControlled
                                  
                                  fieldName={`questions.${index}.uuid`}
                                  type="hidden"
                                  autoComplete="off"
                                  label="Question *"
                                  control={control}
                                  valueGot={item?.uuid}
                                  setValue={setValue}
                                 
                                />
}
                            <Grid item xs={5} md={5} sm={5} >
                              <Fragment key={item.id}>
                                <TextFieldControlled
                                  errors={!!errorMessage?.questions?.[index]?.topic}
                                  fieldName={`questions.${index}.topic`}
                                  type="text"
                                  autoComplete="off"
                                  label="Question *"
                                  control={control}
                                  valueGot={''}
                                  setValue={setValue}
                                  helperText={
                                    errorMessage?.questions?.[index]?.topic &&
                                    errorMessage?.questions?.[index]?.topic?.message
                                  }
                                />
                              </Fragment>
                            </Grid>
                            <Grid item xs={5} md={5} sm={5} >
                              <Fragment key={item.id}>
                                <TextFieldControlled
                                  errors={!!errorMessage?.questions?.[index]?.topic_ar}
                                  fieldName={`questions.${index}.topic_ar`}
                                  type="text"
                                  autoComplete="off"
                                  label="Question Arabic *"
                                  control={control}
                                  valueGot={''}
                                  setValue={setValue}
                                  helperText={
                                    errorMessage?.questions?.[index]?.topic_ar &&
                                    errorMessage?.questions?.[index]?.topic_ar?.message
                                  }
                                />
                              </Fragment>
                            </Grid>
                            <Grid item md={2} style={{ display: 'flex', justifyContent: 'center'}} sx={{mt:2}}>
                              <SwitchFieldDefault
                               
                               fieldName={`questions.${index}.status`}
                                autoComplete="off"
                                label="Status"
                                control={control}
                                disabled={disabled}
                                isLoading={true}
                                setValue={setValue}
                               />
                            </Grid>
                            {/* <Grid item md={6} sx={{ mt: 3 }} style={{ display: 'flex', justifyContent: 'left' }}>
                              <DeleteIcon
                                sx={{ width: 35, height: 35 }}
                                color="error"
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            </Grid> */}
                          </Grid>
                        </Grid>

                      </>

                    )
                  })}

                {task?.length < 10 && (
                  <Grid item xs={12} sm={12} md={12} style={{ display: 'flex', justifyContent: 'left' }}>
                    <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        append({
                          topic: null,
                          topic_ar:null , 
                          status:true
                        });
                      }}
                    >
                      Add task
                    </Button>
                  </Grid>
                )
                }


                <Grid sx={{ mt: 1 }} container spacing={3} direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <Stack direction="row">
                      <AnimateButton>
                        <Button variant="contained" type="submit" sx={{ m: 1 }} className={'red'}>
                          Submit
                        </Button>
                      </AnimateButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid >
            </SubCard>
          </Grid>
        </SubCard>
      </form >
    </>
  );
}
export default TaskManage