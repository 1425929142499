/**
*
* TaskUpdate
*
*/
import * as React from 'react';
import { TaskManage } from 'components/TaskManage/index';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import { getAdmin } from 'services/UserService';
import useAuth from 'hooks/useAuth';
import CustomizedBreadCrumb from 'ui-component/CustomBreadCrumbs';
import { getAdmin } from 'services/UserService';
import { AdminManage } from 'components/AdminManage';

interface Props { }

export default function AdminUpdate(props: Props) {
    const [apiData, setApiData] = useState<any>(null)
    //   const { dispatchLoader } = useAuth();
    const { search } = useLocation()
    let uuid = new URLSearchParams(search).get("uuid");

    const getAdminData = () => {
        // dispatchLoader(true)
        getAdmin({ uuid: uuid }).then((res: any) => {
            console.log("ressss", res)
            setApiData(res[0])
            //   dispatchLoader(false)
        }).catch((err) => {
            //   dispatchLoader(false)
        })
    }
    useEffect(() => {
        getAdminData()
    }, [])

    return (<>
        <CustomizedBreadCrumb title="Admin Update" firstPage="Admin Listing" firstRoute="/dashboard/admin/listing" secondPage="Admin Update" secondRoute="/dashboard/admin/edit" />

        <AdminManage apiData={apiData} />
    </>
    );

};