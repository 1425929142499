import httpservice from "./httpservice";

export function getAdmin(body: any = null) {
    return httpservice.get("/admin", { params: body });
}

export function createAdmin(body: any) {
    return httpservice.post("/admin", body);
}
export function updateAdmin(body: any) {
    return httpservice.put("/admin", body);
}
export function verifyAdmin(body: any) {
    return httpservice.post("/admin/verify", body);
}
export function verifyCode(body: any) {
    return httpservice.put("/admin/verify/code", body);

}
export function changeAdminPasssword(body: any) {
    return httpservice.post("/admin/change/password", body);
}
export function forgetPasswordRequest(body: any) {
    return httpservice.post("/admin/forgot/request", body);
}
export function forgetPasswordChange(body: any) {
    return httpservice.post("/admin/forgot/change", body);
}

export function verifyForgotCode(body: any) {
    return httpservice.put("/admin/forgot/verify/code", body);
}
export function regenerateVerificationCode(body: any) {
    return httpservice.post("/admin/resend", body);
}

export function EnableAdmin(body: any) {
    return httpservice.put("/admin/enable", body);
}
export function DisableAdmin(body: any) {
    return httpservice.put("/admin/disable", body);
}
 
export function getApplicationSetting(body: any = null) {
    return httpservice.get("/setting", { params: body });
}

export function updateApplicationSetting(body: any) {
    return httpservice.put("/setting", body);
}
export const uploadFiles = (files: any) => {
    return httpservice.post('/upload', files, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

