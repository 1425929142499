import widget from './widget';
import Admin from './admin';
import application from './application';
import forms from './forms';
import elements from './elements';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';
import { NavItemType } from 'types';
import Custumer from './custumer';
import Task from './task';
import Questionear from './questionear';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [ 

        Admin,
        // Custumer,
        // Task,
        // Questionear,
        // widget,
        // application,
        // forms,
        // elements,
        // pages,
        // utilities,
        // support,
        // other
    ]
};

export default menuItems;
