import { lazy, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';
import useAuth from 'hooks/useAuth';

const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { user, isLoggedIn } = useAuth();
    const [routes, setRoutes] = useState([LoginRoutes, { path: "*", element: <MaintenanceError />, children: [] }])
    useEffect(() => {
        let userx = "ADMIN"
        if (isLoggedIn && userx == "ADMIN") {
            setRoutes([LoginRoutes, MainRoutes, { path: "*", element: <Navigate to="/dashboard/default" />, children: [] }])
        } else {
            setRoutes(([LoginRoutes,{path:"*", element:<Navigate to="/login" />,children:[]}]))
        }
    }, [user])

    return useRoutes(routes);
}
