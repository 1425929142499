/**
*
* TaskUpdate
*
*/
import * as React from 'react';
import { TaskManage } from 'components/TaskManage/index';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import { getAdmin } from 'services/UserService';
import useAuth from 'hooks/useAuth';
import CustomizedBreadCrumb from 'ui-component/CustomBreadCrumbs';
import { CustomerManage } from 'components/CustomerManage';
import { getCustomer } from 'services/CustomerService';

interface Props { }

export default function CustomerUpdate(props: Props) {
  const [apiData, setApiData] = useState<any>(null)
  //   const { dispatchLoader } = useAuth();
  const { search } = useLocation()
  let uuid = new URLSearchParams(search).get("uuid");

  const getCustomerData = () => {
    // dispatchLoader(true)
    getCustomer({ uuid: uuid }).then((res: any) => {
      setApiData(res[0])
      console.log("update customer",res[0]);
      
      // dispatchLoader(false)
    }).catch((err: any) => {
      //   dispatchLoader(false)
    })
  }
  useEffect(() => {
    getCustomerData()
  }, [])

  return (<>
    <CustomizedBreadCrumb title="Customer Update" firstPage="Customer Listing" firstRoute="/dashboard/customer/listing" secondPage="Customer Update" secondRoute="/dashboard/customer/edit" />

    <CustomerManage apiData={apiData} />
  </>
  );

};