import httpservice from "./httpservice";

export function getTask(body: any = null) {
    return httpservice.get("/task", { params: body });
}

export function createTask(body: any) {
    return httpservice.post("/task", body);
}
export function updateTask(body: any) {
    return httpservice.put("/task", body);
}

export function getTopicByAdmin(body: any = null) {
    return httpservice.get("/topic", { params: body });
}

export function createTopicByAdmin(body: any) {
    return httpservice.post("/topic", body);
}